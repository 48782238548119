




























































import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import {
  OrganizationContact,
  OrganizationPaymentAccount,
  OrganizationAgent,
} from "@/models/organization";
import Page from "@/components/for-page-grid/Page.vue";
import XCardWithList from "@/components/SimpleCardWithList.vue";
import XList from "@/components/hoc/SimpleList.vue";
import CompanyAgentsCard from "@/components/for-company-card-view/CompanyAgentsCard.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";

type ListItem = {
  key: string;
  value: string;
  mdi?: string;
};

@Component({
  components: {
    Page,
    XCardWithList,
    XList,
    CompanyAgentsCard,
  },
  computed: {
    ...mapState({ organization: "organization" }),
    ...mapGetters("organization", {
      organizationId: "id",
      contacts: "contactList",
      paymentAccounts: "paymentAccountList",
      agents: "agentList",
    }),
  },
})
class CompanyCard extends Mixins(AppApiMixin) {
  [x: string]: any;

  pageContentCssClasses = [
    "page__content_for_company-card",
    "box",
    "box_as_responsive-page",
  ];

  get companyDetails(): { general: ListItem[]; contacts: ListItem[] } {
    const { organization } = this.organization;

    const generalRequiredKeys = [
      "название",
      "наименование",
      "адрес",
      "фактадрес",
    ];
    const generalListKeys = [
      "Краткое название",
      "Полное название",
      "Юридический адрес",
      "Фактический адрес",
    ];
    const preparedGeneralList = generalListKeys.map((key, i) => ({
      key,
      value: organization[generalRequiredKeys[i]],
    }));

    const contactRequiredTypes = ["рабочий", "email", "режим работы", "сайт"];
    const contactList = [
      ["mdi-phone", "телефон"],
      ["mdi-email", "email"],
      ["mdi-briefcase", "режим работы"],
      ["mdi-web", "сайт"],
    ];
    const preparedContactList = contactList.map(([mdi, key], i) => {
      const value = this.contacts.find(
        (contact: OrganizationContact) =>
          contact["тип"].toLowerCase() === contactRequiredTypes[i]
      );

      return { mdi, key, value: value && value["значение"] };
    });

    return {
      general: preparedGeneralList,
      contacts: preparedContactList,
    };
  }

  get companyRequisites(): ListItem[] {
    const requiredKeys = [
      "инн",
      "кпп",
      "огрн",
      "оконх",
      "окато",
      "оквэд",
      "окдп",
      "окпо",
    ];

    const { organization } = this.organization;

    return requiredKeys.map((key) => ({ key, value: organization[key] || "" }));
  }

  get companyPaymentAccounts(): Array<ListItem[]> {
    const requiredKeys = ["рсчет", "коррсчет", "бик", "название банка"];
    const listKeys = [
      "Расчетный счет",
      "Корреспондентский счет",
      "БИК",
      "Название банка",
    ];

    return this.paymentAccounts.map((account: OrganizationPaymentAccount) =>
      listKeys.map((key, i) => ({ key, value: account[requiredKeys[i]] }))
    );
  }

  get companyAgents(): {
    name: string;
    position: string;
    contacts: ListItem[];
  } {
    return this.agents.map((agent: OrganizationAgent) => ({
      name: agent["фио"],
      position: agent["должность"],
      contacts: [
        { key: "телефон", value: agent["сотовый"] || agent["рабочий"] },
        { key: "email", value: agent["почта"] },
      ],
    }));
  }

  public mounted() {
    this.getAdditionalOrganizationData(this.organizationId);
  }
}
export default CompanyCard;
