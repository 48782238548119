var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-card-with-list',{attrs:{"title":"Представители","items":_vm.agents,"list-mod-class":"list_card_company-agents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_item = ref.item;
var name = ref_item.name;
var position = ref_item.position;
var contacts = ref_item.contacts;
return [_c('div',{staticClass:"list__agent"},[_c('span',{staticClass:"list__icon icon icon_size_xl icon_color_green rounded"},[_vm._v(" "+_vm._s(_vm._f("getAgentInitials")(name))+" ")]),_c('span',[_vm._v(_vm._s(name))]),_c('span',{staticClass:"list__key"},[_vm._v(_vm._s(position))])]),_vm._l((contacts),function(ref,i){
var key = ref.key;
var value = ref.value;
return _c('div',{key:i,staticClass:"list__agent-contact"},[_c('span',{staticClass:"list__key list__key_size_xs"},[_vm._v(_vm._s(key))]),_c('span',[_vm._v(_vm._s(value))])])})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }